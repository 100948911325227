import { FormsValidationLocalization } from "../types/graphql/generated";

export const genericErrorMsgEnglish = "Something went wrong. Please try again.";

export const validationErrorsEnglish: FormsValidationLocalization = {
  dayRequiredErrorText: "Please select a day",
  emailRequiredErrorText: "Your email address is required",
  fieldRequiredErrorText: "This field cannot be left blank",
  invalidDateErrorText: "Please enter a valid date.",
  invalidPasswordErrorText: "Invalid password",
  invalidPhoneErrorText: "Invalid phone number",
  monthRequiredErrorText: "Please select a month",
  newPasswordRequiredErrorText:
    "Please enter a new password to use for your account",
  passwordRequiredErrorText: "Your password is required",
  requiredErrorText: "Required",
  signupPasswordRequiredErrorText:
    "Please enter a password to use for your account",
  wrongEmailErrorText: "Email address is not valid",
  yearRequiredErrorText: "Please select a year",
  invalidTaxIdErrorText: "Invalid Tax ID",
  invalidTaxIdLengthErrorText: "Must be exactly 10 digits",
};

export const sentryIgnoreErrorsList = [
  // Random plugins/extensions
  "top.GLOBALS",
  "ResizeObserver loop limit exceeded",
  "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
  "Can't find variable: _AutofillCallbackHandler",
  "Failed to fetch",
  "Load failed",
  "The network connection was lost.",
  "TypeError: Failed to fetch",
  "TypeError: NetworkError when attempting to fetch resource.",
  "TypeError: Load failed",
  "TypeError: The network connection was lost.",
  "TypeError: cancelled",
  "cancelled",
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  "originalCreateNotification",
  "canvas.contentDocument",
  "MyApp_RemoveAllHighlights",
  "http://tt.epicplay.com",
  "Can't find variable: ZiteReader",
  "jigsaw is not defined",
  "ComboSearch is not defined",
  "http://loading.retry.widdit.com/",
  "atomicFindClose",
  // Facebook borked
  "fb_xd_fragment",
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  "bmi_SafeAddOnload",
  "EBCallBackMessageReceived",
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  "conduitPage",
  "Network request failed",
  "Error: Document is ready and element #paypal-button does not exist",
  /Error: Request to post \/smart\/api\/payment(.*)$/,
];
