import Link from "next/link";
import React, {
  KeyboardEventHandler,
  MouseEventHandler,
  TouchEventHandler,
} from "react";
import styled from "styled-components";
import { Device } from "../../../../helpers/screenSizes";
import { genericKeyDownHandler } from "../common/helpers";
import { HeaderIconSvg } from "../common/icons";

const StyledArrow = styled.div`
  svg {
    width: 16px;
    path {
      fill: ${({ theme }) => theme.color.disabled};
    }
  }
`;

const Arrow: React.FunctionComponent = () => {
  return <StyledArrow>{HeaderIconSvg.arrow}</StyledArrow>;
};

const StyledMenuItem = styled.div`
  color: #fff;
  cursor: default;
  height: 60px;
  padding: 0 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;

  :hover {
    background-color: #fff;
    color: ${({ theme }) => theme.color.primary};
  }

  @media ${Device.notLaptop} {
    color: ${({ theme }) => theme.color.primary};
    background-color: #fff;
    padding: 0 20px;
    margin-right: 0;
    border-bottom: 1px solid #ebebeb;

    .header-nav-item__header {
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    svg {
      transition: 250ms ease;
    }

    &.nav-menu-open {
      background-color: #ebebeb;
      display: block;
      height: auto;

      svg {
        transform: rotate(180deg);
        transition: 250ms ease;
      }
    }
  }
`;

interface NavMenuItemProps {
  navHeaderDisplay: string;
  links: Link[];
  isOpen: boolean;
  isMobile: boolean;
  identifier: string;
  onMouseEnter: MouseEventHandler;
  onTouchStart: TouchEventHandler;
  onMouseLeave: MouseEventHandler;
  keepOpen: MouseEventHandler;
  onClickHandler: MouseEventHandler;
  onKeyDown: KeyboardEventHandler;
  id?: string;
}
export const NavMenuItem: React.FunctionComponent<NavMenuItemProps> = ({
  navHeaderDisplay,
  isOpen,
  isMobile,
  links,
  identifier,
  keepOpen,
  onClickHandler,
  onMouseEnter,
  onTouchStart,
  onMouseLeave,
  onKeyDown,
  id,
}: NavMenuItemProps) => {
  return (
    <StyledMenuItem
      className={`${isOpen ? `nav-menu-open` : ""}`}
      data-testid={`ui-header-nav-bar-menu-header-${navHeaderDisplay.replace(
        / /g,
        "-"
      )}`}
      data-identifier={identifier}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      tabIndex={0}
      onKeyDown={onKeyDown}
      {...(id && { id })}
    >
      <div
        className={`header-nav-item__header`}
        onTouchStart={onTouchStart}
        data-identifier={identifier}
      >
        {navHeaderDisplay} {isMobile && <Arrow />}
      </div>
      {!!isOpen && (
        <NavMenuItemList
          onClickHandler={onClickHandler}
          links={links}
          onMouseEnter={keepOpen}
        />
      )}
    </StyledMenuItem>
  );
};

const StyledNavMenuItemList = styled.div`
  @media ${Device.laptop} {
    color: ${({ theme }) => theme.color.primary};
    background-color: #fff;
    box-shadow: 0 4px 8px 0 ${({ theme }) => theme.color.disabled};
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    position: absolute;
    top: 60px;
    left: 0px;
    width: 250px;

    display: flex;
    flex-direction: column;

    a.header-nav-item-list__link {
      padding: 16px;
      text-decoration: none;
      color: ${({ theme }) => theme.color.primary};
      cursor: pointer;
      border-bottom: 1px solid #ebebeb;
    }

    > .header-nav-item-list__link:hover {
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0.05) 100%
      );
    }
  }

  @media ${Device.notLaptop} {
    /* on notLaptop, list is only visible if nav open*/
    display: flex;
    flex-direction: column;

    a.header-nav-item-list__link {
      color: ${({ theme }) => theme.color.primary};
      font-size: 16px;
      font-weight: normal;
      height: 40px;
    }
  }
`;

interface NavMenuItemListProps {
  links: Link[];
  onMouseEnter: MouseEventHandler;
  onClickHandler: MouseEventHandler;
}

interface Link {
  display: string;
  url: string;
  id?: string;
}

const NavMenuItemList: React.FunctionComponent<NavMenuItemListProps> = ({
  links,
  onMouseEnter,
  onClickHandler,
}: NavMenuItemListProps) => {
  return (
    <StyledNavMenuItemList
      onMouseEnter={onMouseEnter}
      data-testid="ui-header-nav-menu-list"
    >
      {links.map(({ display, url, id }, index) => {
        return (
          <Link href={url} key={index}>
            <a
              tabIndex={0}
              className="header-nav-item-list__link"
              href={url}
              onClick={onClickHandler}
              onKeyDown={genericKeyDownHandler}
              {...(id && { id })}
            >
              {display}
            </a>
          </Link>
        );
      })}
    </StyledNavMenuItemList>
  );
};
