import React, { useState } from "react";
import styled, { css } from "styled-components";
import { NavMenuItem } from "./NavMenuItem";
import { Device } from "../../../../helpers/screenSizes";
import { MobileUser } from "./MobileUser";
import { HeaderLocation } from "./HeaderLocation";
import { MobileMenuProps } from "../types/header";
import Link from "next/link";

interface StyledMenuProps {
  isMobileMenuOpen: boolean;
  hasSubNavs?: boolean;
}

const StyledMenu = styled.div<StyledMenuProps>`
  @media ${Device.laptop} {
    color: ${({ theme }) => theme.color.text};
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  @media ${Device.notMobile} {
    .header-location {
      display: none;
    }
  }

  @media ${Device.notLaptop} {
    ${({ isMobileMenuOpen }) => !isMobileMenuOpen && `display: none;`};
    ${({ isMobileMenuOpen, hasSubNavs }) =>
      isMobileMenuOpen &&
      css`
        min-height: 100vh;
        background-color: ${({ theme }) => theme.color.disabled};
        color: ${({ theme }) => theme.color.primary};
        position: absolute;
        width: 100%;
        left: 0;
        top: ${hasSubNavs ? `110px` : `60px`};

        display: flex;
        flex-direction: column;
      `};
  }
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  height: 60px;

  @media ${Device.laptop} {
    color: ${({ theme }) => theme.header?.shop?.navLinkColor || "#fff"};
    cursor: pointer;
    padding: 0 20px;
    margin-right: 20px;
    white-space: nowrap;

    :hover {
      background-color: #fff;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  @media ${Device.notLaptop} {
    color: ${({ theme }) =>
      theme.header?.shop?.mobileLinkColor || theme.color.primary};
    background-color: ${({ theme }) =>
      theme.header?.shop?.backgroundColor || "#fff"};
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    padding-left: 20px;
  }
`;

export const NavMenu: React.FunctionComponent<MobileMenuProps> = ({
  navItems,
  onClickHandler,
  isOpen = false,
  keepMobileMenuOpen,
  hasSubNavs,
  user,
  location,
}) => {
  const customHTMLAttributeName = "data-identifier";
  const [keyOfOpenNavItem, setIndex] = useState("");

  const closeAllMenus = (): void => {
    setIndex("");
  };

  const openSpecificMenu = (event: React.MouseEvent): void => {
    const key = event.currentTarget.getAttribute(customHTMLAttributeName) || "";
    setIndex(key);
  };

  const keepMenuOpen = (): void => {
    setIndex(keyOfOpenNavItem);
  };

  const toggleMenu = (event: React.MouseEvent | React.TouchEvent): void => {
    const key = event.currentTarget.getAttribute(customHTMLAttributeName) || "";
    // if the key matches keyOfOpenNavItem, it's already open, so toggle it closed
    setIndex(key === keyOfOpenNavItem ? "" : key);
  };

  const onClickWrapper = (event: React.MouseEvent): void => {
    // close the menus
    closeAllMenus();
    // close the mobile menu
    if (keepMobileMenuOpen) {
      keepMobileMenuOpen(false);
    }
    // continue with the original click handler
    onClickHandler(event);
  };

  const onKeyDownHandler = (event: React.KeyboardEvent): void => {
    // To enable keyboard access on navigation menus
    // - enter / space key opens menu (enter again at the top closes menu)
    // - escape closes menu
    switch (event.key) {
      case "Enter":
      case " ": {
        const key =
          event.currentTarget.getAttribute(customHTMLAttributeName) || "";
        setIndex(key === keyOfOpenNavItem ? "" : key);
        return;
      }
      case "Escape": {
        closeAllMenus();
        return;
      }
      default:
        return;
    }
  };

  return (
    <StyledMenu isMobileMenuOpen={isOpen} hasSubNavs={hasSubNavs}>
      <MobileUser {...user} />
      <HeaderLocation {...location} keepMobileMenuOpen={keepMobileMenuOpen} />
      {navItems.map((item, index) => {
        const optionalProps = {
          ...(item.id && { id: item.id }),
        };

        if (item.links) {
          return (
            <NavMenuItem
              key={index}
              navHeaderDisplay={item.navHeaderDisplay}
              links={item.links || []}
              isOpen={
                `${item.navHeaderDisplay.replace(/ /g, "")}${index}` ===
                keyOfOpenNavItem
              }
              isMobile={isOpen}
              onMouseEnter={openSpecificMenu}
              onMouseLeave={closeAllMenus}
              onTouchStart={toggleMenu}
              onKeyDown={onKeyDownHandler}
              keepOpen={keepMenuOpen}
              onClickHandler={onClickWrapper}
              identifier={`${item.navHeaderDisplay.replace(/ /g, "")}${index}`}
              {...optionalProps}
            />
          );
        } else {
          return (
            <Link key={index} href={item.navHeaderLink || ""}>
              <StyledLink
                data-testid={`ui-header-nav-menu-link-${item.navHeaderDisplay.replace(
                  / /g,
                  "-"
                )}`}
                className="header-nav-item header-nav-item__header"
                target={item.isOpenNewTab ? `_blank` : `_self`}
                href={item.navHeaderLink || ""}
                onClick={onClickWrapper}
                {...optionalProps}
              >
                {item.navHeaderDisplay}
              </StyledLink>
            </Link>
          );
        }
      })}
    </StyledMenu>
  );
};
