import styled, { css } from "styled-components";
import { HeadingStyleProps } from ".";
import { Device } from "../../styles/screenSizes";

const headingStyles = css<HeadingStyleProps>`
  margin: 0;
  padding: 0;
  ${({ theme, size, color }) => {
    const {
      fontSize,
      fontFamily,
      fontWeight,
      letterSpacing,
      lineHeight,
      textTransform,
      color: themeColor,
    } = theme.typography.heading[size];
    const headingColor = color || themeColor;
    return `
      font-size: ${fontSize?.desktop};
      font-family: ${fontFamily || "font-primary"};
      font-weight: ${fontWeight || "400"};
      line-height: ${lineHeight || "normal"};
      letter-spacing: ${letterSpacing || "normal"};
      ${textTransform ? `text-transform: ${textTransform};` : ""}
      ${headingColor ? `color: ${headingColor};` : ""}
      ${
        fontSize?.mobile
          ? `
        @media ${Device.mobile} {
          font-size: ${fontSize?.mobile};
        }
      `
          : ""
      };
    `;
  }};
  ${({ center }) => center && `text-align: center`};
`;

export const StyledHeading1 = styled.h1`
  ${headingStyles};
`;

export const StyledHeading2 = styled.h2`
  ${headingStyles};
`;

export const StyledHeading3 = styled.h3`
  ${headingStyles};
`;

export const StyledHeading4 = styled.h4`
  ${headingStyles};
`;

export const StyledHeading5 = styled.h5`
  ${headingStyles};
`;

export const StyledHeading6 = styled.h6`
  ${headingStyles};
`;
