export interface GenericErrorExtensions<ErrorKeys> {
  details?: {
    key: ErrorKeys;
  }[];
}

export enum LoginErrorKeys {
  error = "error",
  accountLocked = "accountLocked",
  passwordResetRequired = "passwordResetRequired",
}
