import { GraphQLError } from "graphql";
import { genericErrorMsgEnglish } from "../constants";
import { logUnknownErrorCode } from "./logAndCaptureInSentry";
import { GenericErrorExtensions, LoginErrorKeys } from "../types/error";
import { FormsSignInLocalization } from "../types/graphql/generated";

export const parseLoginGraphQLErrors = (
  graphQLErrors: GraphQLError[],
  localization?: FormsSignInLocalization | null
) => {
  let errorMsg = "";
  let passwordResetRequired = false;

  // 3 possible error keys are: error, account_locked, password_reset_required

  graphQLErrors.forEach((error: GraphQLError) => {
    const errorDetails =
      (error.extensions as GenericErrorExtensions<LoginErrorKeys>)?.details ||
      [];
    errorDetails.forEach((errorDetail) => {
      const { key } = errorDetail;
      switch (key) {
        case LoginErrorKeys.accountLocked:
          errorMsg =
            localization?.accountLockedErrorText ||
            "Account locked. Please contact support for assistance.";
          break;
        case LoginErrorKeys.error:
          errorMsg =
            localization?.genericErrorText ||
            "Oops, that email and password combination isn't correct. Please check and try again or try resetting your password.";
          break;
        case LoginErrorKeys.passwordResetRequired:
          errorMsg = "";
          passwordResetRequired = true;
          break;
        default:
          logUnknownErrorCode(key, "login");
          errorMsg = genericErrorMsgEnglish;
      }
    });
  });

  return { errorMsg, passwordResetRequired };
};
