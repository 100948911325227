import React, { useContext, useState } from "react";
import Link from "next/link";
import { useRouterWithRedirect } from "hooks/useRouterWithRedirect";
import { AppContext } from "context/app";
import { ModalContext } from "context/modal";
import { UserContext } from "context/user";
import { LoginForm as PepLoginForm } from "@pepdirect/ui/login";
import { Heading } from "@pepdirect/ui/heading";
import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import { logEvent } from "@pepdirect/helpers/analyticsLogger";
import { parseLoginGraphQLErrors } from "@pepdirect/v3/helpers/errors";
import { UpdatePassword } from "@pepdirect/v3/ui/UpdatePassword";
import { getLinkFromTenant } from "helpers/tenant";
import { LoginDTO } from "types/user";

import { endpoints } from "config";
import st from "./login.module.scss";
import { EVENT_NAMES } from "@pepdirect/shared/types";
import { useLoginMutation } from "services/graphql/generated";

export function LoginForm({
  inModal = false,
}: {
  inModal?: boolean;
}): JSX.Element {
  const [loginMutation, { loading }] = useLoginMutation();
  const [mappedErrorMessage, setMappedErrorMessage] = useState("");
  const [showUpdatePasswordScreen, setShowUpdatePasswordScreen] =
    useState(false);
  const { tenant } = useContext(AppContext);
  const { open, close } = useContext(ModalContext);
  const { setUser } = useContext(UserContext);
  const { pushPreserveRedirect, backToRedirect, getUrlWithRedirect } =
    useRouterWithRedirect();

  const privacyLink = getLinkFromTenant(tenant, "privacy");
  const termsLink = getLinkFromTenant(tenant, "terms");

  const onSubmit = async (formValue: LoginDTO) => {
    setMappedErrorMessage("");
    try {
      const { data } = await loginMutation({
        variables: { email: formValue.login, password: formValue.password },
      });
      setUser(data?.login?.id);
      logEvent(data?.login?.id, EVENT_NAMES.userLoginSuccess);
      inModal ? close(data?.login?.id) : backToRedirect();
    } catch (e) {
      if (e instanceof ApolloError && e.graphQLErrors.length) {
        const { errorMsg, passwordResetRequired } = parseLoginGraphQLErrors(
          e.graphQLErrors as GraphQLError[]
        );

        if (passwordResetRequired) {
          setShowUpdatePasswordScreen(true);
        } else {
          setMappedErrorMessage(errorMsg);
        }
      }
      return;
    }
  };

  const goToForgotPassword = () => {
    if (inModal) {
      open("FORGOT_PASSWORD_MODAL");
    } else {
      close(null);
      pushPreserveRedirect("/forgot-password");
    }
  };

  if (showUpdatePasswordScreen) {
    return (
      <UpdatePassword
        logoSrc={`${endpoints.cdn}/static-images/pantry-shop-icons/dark-large-pantry-shop-logo.svg`}
        onClose={() => {
          close(null);
        }}
      />
    );
  }

  return (
    <PepLoginForm
      logoSrc={`${endpoints.cdn}/static-images/pantry-shop-icons/dark-large-pantry-shop-logo.svg`}
      onSubmit={onSubmit}
      goToForgotPassword={goToForgotPassword}
      isLoading={loading}
      error={mappedErrorMessage || ""}
      termsUrl={termsLink?.url}
      privacyUrl={privacyLink?.url}
    >
      <div className={st.title}>
        <Heading level="h2" size="l">
          New to Pantry Shop?
        </Heading>
      </div>
      {/* not using <Button> here because of getUrlWithRedirect */}
      <Link href={getUrlWithRedirect("/sign-up")}>
        <a className={st.linkAsButton} onClick={() => close(null)}>
          Create an Account
        </a>
      </Link>
    </PepLoginForm>
  );
}
