export type FontNames = "font-primary" | "font-secondary";

export const fontNames: Record<FontNames, FontNames> = {
  "font-primary": "font-primary",
  "font-secondary": "font-secondary",
};

export type FontWeights = "300" | "400" | "500" | "600" | "700";

export const fontWeights: Record<FontWeights, FontWeights> = {
  "300": "300",
  "400": "400",
  "500": "500",
  "600": "600",
  "700": "700",
};

export type TextTransform =
  | "uppercase"
  | "lowercase"
  | "capitalize"
  | "inherit";

export const textTransform: Record<TextTransform, TextTransform> = {
  uppercase: "uppercase",
  lowercase: "lowercase",
  capitalize: "capitalize",
  inherit: "inherit",
};

type hex3Digit = `${number | string}${number | string}${number | string}`;
export type HexColor = `#${hex3Digit}` | `#${hex3Digit}${hex3Digit}`;

export type RGBAColor = `rgba(${number}, ${number}, ${number}, ${number})`;
