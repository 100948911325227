import React, { FC, ReactNode } from "react";
import Link, { LinkProps } from "next/link";
import { StyledAnchor } from "./index.styled";

export interface InternalLinkProps extends LinkProps {
  children?: ReactNode;
  color?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  center?: boolean;
  fill?: boolean;
  // `fill` is an actual DOM element that is reserved for a non-boolean
  // value. Adding a transient prop of `$fill` so styled-components doesn't
  // place `fill` in the DOM but the consumer of <InternalLink> still gets
  // to declare the prop `fill` ( see usage below: $fill={fill} )
  // https://styled-components.com/docs/api#transient-props
  $fill?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  noWrap?: boolean;
  onClick?: () => void;
  // https://styled-components.com/docs/advanced#styling-normal-react-components
  // this is needed to extend styles, ex:
  //
  // const StyledInternalLink = styled(InternalLink)`
  //   color: blue;
  // `;
  className?: string;
}

/**
 * https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/402#issuecomment-778567785
 * example usage
 * <InternalLink href="/about" color="#003069" center>About</InternalLink>
 *
 * For internal links that looks like buttons, but are NOT actual buttons in
 * the DOM (because you should not nest interactive components) please use:
 * <Button internalLink="/" type="secondary">
 */
export const InternalLink: FC<InternalLinkProps> = ({
  color = "",
  fontFamily = "",
  fontSize = "",
  fontWeight = "",
  center = false,
  fill = false,
  underline = false,
  uppercase = false,
  noWrap = false,
  onClick,
  children,
  className,
  ...linkProps
}) => {
  return (
    <Link {...linkProps} passHref>
      <StyledAnchor
        href="passRef"
        color={color}
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
        center={center}
        $fill={fill}
        underline={underline}
        uppercase={uppercase}
        noWrap={noWrap}
        onClick={onClick}
        data-testid="internal-link-anchor"
        className={className || ""}
      >
        {children}
      </StyledAnchor>
    </Link>
  );
};
