import React, { FC } from "react";
import { useRouter } from "next/router";
import { Form } from "react-final-form";
import styled from "styled-components";
import { Button } from "../button/Button";
import { Heading } from "../heading/Heading";
import { FieldInput } from "../fields/FieldInput";
import { ExternalLink } from "../link/ExternalLink";
import { validateWithYup, getForgotPasswordSchema } from "./validation";
import { colors } from "../../styles/variables";
import {
  BrandConfigCustomizationsImage,
  FormsForgotPasswordLocalization,
  FormsValidationLocalization,
  PortalAltsLocalization,
} from "../../graphql/generated";
import { ChevronIconSvg } from "../icons";
import { TemplateAsComponent } from "../TemplateAsComponent";

const AccountLogo = styled.img`
  margin-bottom: 70px;
`;

const LinkWrapper = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
  > :hover {
    cursor: pointer;
  }
`;

const StyledForgotPasswordForm = styled.div`
  margin: auto;
  max-width: 448px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  button:last-child {
    margin-top: 15px;
  }
`;

const Title = styled.div`
  margin-bottom: 10px;
  align-self: center;
`;

const Text = styled.p`
  text-align: center;
  font-family: inherit;
  color: ${({ theme }) => theme.color.disabled};
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.color.primary};
`;

const Error = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
  margin-top: -5px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.error};
`;

interface ForgotPasswordFormProps {
  accountLogo?: BrandConfigCustomizationsImage;
  contactLinkUrl?: string;
  fallbackUrl?: string;
  isLoading: boolean;
  isSubmitted: boolean;
  error: string;
  onSubmit: (formValue: { email: string }) => void;
  toLogin: () => void;
  forgotPasswordLocalization?: FormsForgotPasswordLocalization | null;
  altsLocalization?: PortalAltsLocalization | null;
  validationLocalization?: FormsValidationLocalization | null;
}

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
  accountLogo,
  contactLinkUrl,
  isLoading,
  isSubmitted,
  error,
  onSubmit,
  toLogin,
  forgotPasswordLocalization,
  altsLocalization,
  validationLocalization,
}) => {
  const {
    headingText = "Forgot Password",
    submittedText = "Thank you. If a matching email exists we will send you an email in a few minutes with instructions on how to reset your password.",
    contactLinkText = "Please check your spam folder if you cannot find the email. For further assistance, please ${linkText}.",
    contactLinkContentText = "contact us",
    formDescriptionText = "Enter your email and we will send you a password reset link",
    emailLabelText = "Email Address",
    submitButtonText = "Send",
    backToLoginText = "Back to Login",
  } = forgotPasswordLocalization || {};
  const { accountAltText = "Account" } = altsLocalization || {};
  const router = useRouter();

  return (
    <StyledForgotPasswordForm>
      {!!accountLogo && (
        <div>
          <LinkWrapper
            onClick={() => {
              router.back();
            }}
          >
            <ChevronIconSvg />
          </LinkWrapper>
          <AccountLogo src={accountLogo.src} alt={accountAltText} />
        </div>
      )}
      <Title>
        <Heading level="h2" size="l" color={colors.darkGray}>
          {headingText}
        </Heading>
      </Title>

      {isSubmitted ? (
        <>
          <p data-testid="forgot-password-submitted">{submittedText}</p>
          {contactLinkUrl && (
            <TemplateAsComponent
              template={contactLinkText}
              map={{
                linkText: (
                  <StyledExternalLink href={contactLinkUrl}>
                    {contactLinkContentText}
                  </StyledExternalLink>
                ),
              }}
            />
          )}
        </>
      ) : (
        <>
          <Text>{formDescriptionText}</Text>
          <Form
            onSubmit={onSubmit}
            validate={validateWithYup(
              getForgotPasswordSchema(validationLocalization)
            )}
            render={({ handleSubmit }) => (
              <StyledForm onSubmit={handleSubmit}>
                <FieldInput
                  label={emailLabelText}
                  name="email"
                  autoComplete="email"
                />
                <Button htmlType="submit" loading={isLoading} fullWidth>
                  {submitButtonText}
                </Button>
                {error && <Error>{error}</Error>}
              </StyledForm>
            )}
          />
          <Button type="tertiary" fullWidth onClick={toLogin}>
            {backToLoginText}
          </Button>
        </>
      )}
    </StyledForgotPasswordForm>
  );
};
