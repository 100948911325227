import { parse } from "urijs";
import { ImageLoader } from "next/dist/client/image";

interface CloudflareImageOptions {
  width?: number;
  height?: number;
  dpr?: number;
  fit?: "scale-down" | "contain" | "cover" | "crop" | "pad";
  gravity?: "left" | "right" | "top" | "bottom" | string;
  quality?: number;
  format?: "auto";
  onerror?: "redirect";
  metadata?: "keep" | "copyright" | "none";
}

export const isCloudinaryUrl = (url: string): boolean =>
  url.includes("res.cloudinary.com");

export const isCloudflareImage = (url: string): boolean =>
  url.includes("/cdn-cgi/image/");

export const getImagePathFromUsualUrl = (url: string): string => {
  const uri = parse(url);
  return uri.path || "";
};

export const replaceCfOptionsInCfUrl = (url: string, options: string): string =>
  url.replace(/format=auto[^/]*/, options);

export const getImagePathFromOurCloudinaryUrl = (url: string): string => {
  // We know that images are uploaded to this folder
  const prefix = "/product-imgs/";
  const sizes = ["345", "600", "690", "original"];
  const parts = url.split("/");
  let index = parts.length - 1;
  sizes.forEach((size) => {
    const foundIdx = parts.findIndex((x) => x === size);
    if (foundIdx > -1) index = foundIdx;
  });
  const path = parts.slice(index).join("/");
  return `${prefix}${path}`;
};

export const getImagePathFromAnyUrl = (url: string): string =>
  isCloudinaryUrl(url)
    ? getImagePathFromOurCloudinaryUrl(url)
    : getImagePathFromUsualUrl(url);

export const replaceWidthFolder = (path: string): string => {
  // Hard-coded sizes, we can load them from variable though later
  const sizes = ["345", "600", "690"];
  const original = "/original/";
  const regexpString = sizes.join("|");
  const regexp = new RegExp(`/(${regexpString})/`, "g");
  return path.replace(regexp, original);
};

export const transformCloudflareOptionsToString = (
  options: CloudflareImageOptions
): string =>
  Object.entries(options)
    .map(([key, value]) => `${key}=${value}`)
    .join(",");

export const getCloudflareImageUrl = (
  cfDomain: string,
  url: string,
  options: CloudflareImageOptions
): string => {
  // Nothing in - nothing out
  if (!url) return "";

  const defaultOptions: CloudflareImageOptions = {
    format: "auto",
    metadata: "copyright",
  };
  const mergedOptions: CloudflareImageOptions = {
    ...defaultOptions,
    ...options,
  };
  const cfOptionsString = transformCloudflareOptionsToString(mergedOptions);

  if (!isCloudflareImage(url)) {
    const cfPrefix = "/cdn-cgi/image/";
    const path = replaceWidthFolder(getImagePathFromAnyUrl(url));
    return `${cfDomain}${cfPrefix}${cfOptionsString}${path}`;
  } else {
    return replaceCfOptionsInCfUrl(url, cfOptionsString);
  }
};

export const getCloudflareImageSrcSet = (
  cfDomain: string,
  url: string,
  width: number,
  dpr = [1, 2]
): { src: string; srcSet: string } => ({
  src: getCloudflareImageUrl(cfDomain, url, { width }),
  srcSet: dpr
    .map(
      (dpr) => `${getCloudflareImageUrl(cfDomain, url, { width, dpr })} ${dpr}x`
    )
    .join(", "),
});

// We still want to provide cfDomain in case there are some non-cloudflare links in the codebase. If image is cloudflare, though, domain won't be replaced.
export const getCloudflareLoader =
  (cfDomain: string): ImageLoader =>
  ({ src, width, quality }) => {
    return getCloudflareImageUrl(cfDomain, src, {
      width,
      quality: quality || 75,
    });
  };
